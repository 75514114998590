
import { defineComponent } from "vue";

import CardBackground from "@/components/auth/FormBackground.vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseCard from "@/components/common/BaseCard.vue";
import { CredentialsData } from "@/models/users";
import FirebaseAuth from "@/controllers/firebase-auth";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default defineComponent({
  name: "LoginForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CardBackground,
    BaseCard,
    BaseField,
    BaseButton,
  },
  data() {
    return {
      credentials: {} as CredentialsData,
    };
  },
  emits: ["recoverPassword"],
  methods: {
    async signIn(e: Event): Promise<void> {
      e.preventDefault();
      await FirebaseAuth.signIn(this.credentials);
    },
    recoverPassword(e: Event): void {
      e.preventDefault();
      this.$emit("recoverPassword");
    },
  },
  validations() {
    return {
      credentials: {
        email: { required, email },
        password: { required, minLength: minLength(6) },
      },
    };
  },
});
