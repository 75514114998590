<template>
  <a-spin :spinning="loading" :delay="250">
    <form class="cc-form">
      <div class="cc-logo all columns">
        <img
          src="@/assets/common/centro-cristiano.svg"
          alt="Iglesia Centro Cristiano"
        />
      </div>
      <div class="all columns">
        <h2 class="global-title greet">¿Olvidaste tu contraseña?</h2>
      </div>
      <BaseField
        class="all"
        v-model="email"
        type="email"
        label="Correo electrónico:"
        placeholder="Correo electrónico"
      />
      <BaseButton
        text="Obtener contraseña"
        class="all columns action"
        @click="resetPassword"
      />
    </form>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

export default defineComponent({
  name: "LoginForm",
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
    };
  },
  emits: ["sendResetEmail"],
  methods: {
    async resetPassword(e: Event): Promise<void> {
      e.preventDefault();
      this.$emit("sendResetEmail", this.email);
    },
  },
});
</script>

<style scoped>
.cc-logo {
  display: flex;
  justify-content: center;
}

.cc-logo img {
  height: 6rem;
  width: auto;
}

.global-title {
  margin: 0;
}

.greet {
  text-align: center;
  font-size: 2rem;
}

.description {
  font-size: 1.4rem;
}

.cc-form .action {
  margin-top: 1rem;
}

@media only screen and (min-width: 768px) {
  .splitted-cnt {
    flex-flow: row;
  }

  .card-img {
    width: 42%;
    height: 100%;
  }

  .card-form {
    width: 58%;
    height: 100%;
  }
}
</style>
