<template>
  <div class="action-container">
    <PasswordResetForm
      @confirm-password="resetPassword"
      v-if="validMode && !actionComplete"
    />
  </div>
</template>

<script lang="ts">
import FirebaseAuth from "@/controllers/firebase-auth";
import { defineComponent } from "vue";
import PasswordResetForm from "@/components/auth/ActionForm.vue";
import firebase from "firebase/app";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "ActionHandler",
  setup() {
    useMeta({
      title: "Recupera tu contraseña",
      description: `Restablece la contraseña de tu cuenta.`,
    });
  },
  components: {
    PasswordResetForm,
  },
  mounted() {
    this.loadingState = true;
    const query = this.$route.query;
    this.mode = query.mode as string;
    this.actionCode = query.oobCode as string;
    this.continueUrl = query.continueUrl as string;
    this.apiKey = query.apiKey as string;
    this.verifyAction();
  },
  watch: {
    actionComplete: function () {
      if (this.actionComplete) {
        location.href = this.continueUrl;
      }
    },
  },
  data() {
    return {
      mode: "",
      actionCode: "",
      continueUrl: "",
      apiKey: "",
      auth: {} as firebase.auth.Auth,
      email: undefined as string | undefined,
      actionComplete: false,
      loadingState: true,
    };
  },
  computed: {
    validMode(): boolean {
      return this.mode === "resetPassword";
    },
  },
  methods: {
    async verifyAction() {
      var config = {
        apiKey: this.apiKey,
      };
      var app = firebase.initializeApp(config);
      this.auth = app.auth();
      if (this.validMode) {
        this.email = await FirebaseAuth.checkActionCode(
          this.auth,
          this.actionCode
        );
      } /* else {
        location.href = this.continueUrl;
      } */
      this.loadingState = false;
    },
    async resetPassword(password: string) {
      this.loadingState = true;
      this.actionComplete = await FirebaseAuth.confirmPasswordReset(
        this.auth,
        this.actionCode,
        password
      );
      this.loadingState = false;
    },
  },
});
</script>

<style scoped>
.action-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
