<template>
  <div>
    <div class="login-container">
      <Form class="login-form" @recover-password="showModal = true" />
    </div>
    <div class="copyright">
      <img
        src="@/assets/common/centro-cristiano.svg"
        alt="Iglesia Centro Cristiano"
      />
      <h3>&copy; {{ copyrightText }}</h3>
    </div>
  </div>
  <a-modal
    v-model:visible="showModal"
    title="Iglesia Centro Cristiano"
    :destroyOnClose="true"
    :footer="null"
  >
    <RecoverForm :loading="loadingState" @send-reset-email="sendResetEmail" />
  </a-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Form from "@/components/auth/Form.vue";
import RecoverForm from "@/components/auth/PasswordForm.vue";
import UsersController from "@/controllers/users";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "AuthLogin",
  setup() {
    useMeta({
      title: "Iniciar Sesión",
      description: `Ingresa tus credenciales y accede a CMS Centro Cristiano`,
    });
  },
  components: {
    Form,
    RecoverForm,
  },
  data() {
    return {
      showModal: false,
      loadingState: false,
    };
  },
  computed: {
    copyrightText(): string {
      const date = new Date();
      return `${date.getFullYear()} Iglesia Centro Cristiano.`;
    },
  },
  methods: {
    async sendResetEmail(email: string) {
      this.loadingState = true;
      const result = await UsersController.resetPassword(email);
      this.loadingState = false;
      if (result) {
        const notification = new PopUpMessage({
          title: "Éxito",
          message: "Correo electrónico enviado éxitosamente.",
          type: NotificationType.Success,
        });
        notification.show();
        this.showModal = false;
      }
    },
  },
});
</script>

<style scoped>
.login-container {
  display: flex;
  height: calc(92vh - 6.3rem);
  min-height: 550px;
  justify-content: center;
  align-items: center;
}

.login-form {
  padding: 0;
  width: 80%;
  height: 75%;
  min-height: 450px;
}

.copyright {
  padding-bottom: 1.2rem;
}

.copyright img {
  margin-bottom: 0.5rem;
  height: 2.6rem;
  width: auto;
}

.copyright h3 {
  font-size: 1rem;
  font-weight: var(--f-medium);
}

@media only screen and (min-width: 768px) {
  .login-container {
    height: calc(100vh - 6.3rem);
  }

  .login-form {
    width: clamp(600px, 44%, 720px);
    height: clamp(460px, 45%, 490px);
  }
}
</style>
