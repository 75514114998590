
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "ActionForm",
  components: {
    BaseCard,
    BaseField,
    BaseButton,
  },
  data() {
    return {
      password: "",
    };
  },
  emits: ["confirmPassword"],
  methods: {
    async signIn(e: Event): Promise<void> {
      e.preventDefault();
      this.$emit("confirmPassword", this.password);
    },
  },
});
