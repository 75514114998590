
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

export default defineComponent({
  name: "LoginForm",
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
    };
  },
  emits: ["sendResetEmail"],
  methods: {
    async resetPassword(e: Event): Promise<void> {
      e.preventDefault();
      this.$emit("sendResetEmail", this.email);
    },
  },
});
