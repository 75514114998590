<template>
  <BaseCard>
    <div class="splitted-cnt">
      <CardBackground class="card-img" />
      <div class="card-form">
        <div>
          <h2 class="global-title greet">¡Bienvenido de nuevo!</h2>
          <h3 class="global-title description">Ingresa tus crendenciales</h3>
        </div>
        <form class="cc-form">
          <BaseField
            class="all"
            v-model="credentials.email"
            type="email"
            label="Correo electrónico:"
            placeholder="Correo electrónico"
          />
          <BaseField
            class="all"
            v-model="credentials.password"
            type="password"
            label="Contraseña:"
            placeholder="Contraseña"
          />
          <BaseButton
            text="Entrar"
            class="all columns action"
            :disabled="v$.$invalid"
            @click="signIn"
          />
          <a
            class="forgotten-password all columns"
            href="#"
            target="_blank"
            @click="recoverPassword"
            >¿Olvidaste tu contraseña?</a
          >
        </form>
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CardBackground from "@/components/auth/FormBackground.vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseCard from "@/components/common/BaseCard.vue";
import { CredentialsData } from "@/models/users";
import FirebaseAuth from "@/controllers/firebase-auth";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default defineComponent({
  name: "LoginForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CardBackground,
    BaseCard,
    BaseField,
    BaseButton,
  },
  data() {
    return {
      credentials: {} as CredentialsData,
    };
  },
  emits: ["recoverPassword"],
  methods: {
    async signIn(e: Event): Promise<void> {
      e.preventDefault();
      await FirebaseAuth.signIn(this.credentials);
    },
    recoverPassword(e: Event): void {
      e.preventDefault();
      this.$emit("recoverPassword");
    },
  },
  validations() {
    return {
      credentials: {
        email: { required, email },
        password: { required, minLength: minLength(6) },
      },
    };
  },
});
</script>

<style scoped>
.splitted-cnt {
  width: 100%;
  height: 100%;
  flex-flow: column;
  display: flex;
}

.card-img {
  height: 25%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.card-form {
  height: 75%;
  padding: 2.4rem;
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
}

.login-logo img {
  margin: 1rem auto;
  width: 20%;
  height: auto;
}

.global-title {
  margin: 0;
}

.greet {
  font-size: 2rem;
}

.description {
  font-size: 1.4rem;
}

.cc-form {
  padding: 1rem;
}

.cc-form .action {
  margin-top: 1rem;
}

.forgotten-password {
  text-align: center;
  color: var(--c-harvest-gold);
  font-size: 1.2rem;
  margin: 1rem auto 0;
}

.forgotten-password:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .splitted-cnt {
    flex-flow: row;
  }

  .card-img {
    width: 42%;
    height: 100%;
  }

  .card-form {
    width: 58%;
    height: 100%;
  }
}
</style>
