
import { defineComponent } from "vue";
import Form from "@/components/auth/Form.vue";
import RecoverForm from "@/components/auth/PasswordForm.vue";
import UsersController from "@/controllers/users";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "AuthLogin",
  setup() {
    useMeta({
      title: "Iniciar Sesión",
      description: `Ingresa tus credenciales y accede a CMS Centro Cristiano`,
    });
  },
  components: {
    Form,
    RecoverForm,
  },
  data() {
    return {
      showModal: false,
      loadingState: false,
    };
  },
  computed: {
    copyrightText(): string {
      const date = new Date();
      return `${date.getFullYear()} Iglesia Centro Cristiano.`;
    },
  },
  methods: {
    async sendResetEmail(email: string) {
      this.loadingState = true;
      const result = await UsersController.resetPassword(email);
      this.loadingState = false;
      if (result) {
        const notification = new PopUpMessage({
          title: "Éxito",
          message: "Correo electrónico enviado éxitosamente.",
          type: NotificationType.Success,
        });
        notification.show();
        this.showModal = false;
      }
    },
  },
});
