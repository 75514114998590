<template>
  <BaseCard>
    <form class="cc-form">
      <div class="cc-logo all columns">
        <img
          src="@/assets/common/centro-cristiano.svg"
          alt="Iglesia Centro Cristiano"
        />
      </div>
      <BaseField
        class="all"
        v-model="password"
        type="password"
        label="Nueva contraseña:"
        placeholder="Nueva contraseña"
      />
      <BaseButton text="Confirmar" class="all columns action" @click="signIn" />
    </form>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "ActionForm",
  components: {
    BaseCard,
    BaseField,
    BaseButton,
  },
  data() {
    return {
      password: "",
    };
  },
  emits: ["confirmPassword"],
  methods: {
    async signIn(e: Event): Promise<void> {
      e.preventDefault();
      this.$emit("confirmPassword", this.password);
    },
  },
});
</script>

<style scoped>
.cnt-card {
  width: 90%;
  min-width: 300px;
}

.cc-logo {
  display: flex;
  justify-content: center;
}

.cc-logo img {
  height: 6rem;
  width: auto;
}

.global-title {
  margin: 0;
}

.greet {
  text-align: center;
  font-size: 2rem;
}

.description {
  font-size: 1.4rem;
}

.cc-form .action {
  margin-top: 1rem;
}

@media only screen and (min-width: 768px) {
  .cnt-card {
    width: 70%;
  }

  .splitted-cnt {
    flex-flow: row;
  }

  .card-img {
    width: 42%;
    height: 100%;
  }

  .card-form {
    width: 58%;
    height: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .cnt-card {
    width: 25%;
  }
}
</style>
