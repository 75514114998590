<template>
  <div class="background">
    <div class="missions-image">
      <img
        src="@/assets/common/auth-image.jpg"
        alt="Misiones hasta que Cristo venga"
      />
    </div>
    <div class="blur-cover" />
    <div class="cc-logo">
      <img
        src="@/assets/common/centro-cristiano-white.svg"
        alt="Iglesia Centro Cristiano"
      />
    </div>
    <h3 class="version">Version 1.0</h3>
  </div>
</template>

<style scoped>
.background {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
}

.background .missions-image,
.background .blur-cover,
.background .cc-logo {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.background .missions-image {
  z-index: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.background .blur-cover {
  z-index: 2;
  background: rgba(0, 0, 0, 0.75);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.background .cc-logo {
  z-index: 3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.background .version {
  z-index: 4;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  margin: 0;
  bottom: 1.2rem;
  left: 2rem;
  position: absolute;
}

.missions-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.cc-logo img {
  margin: auto;
  height: 45%;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .background {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: relative;
  }

  .background .missions-image {
    z-index: 1;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .background .blur-cover {
    z-index: 2;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .missions-image img {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .cc-logo img {
    margin: auto;
    width: 45%;
    height: auto;
  }
}
</style>
